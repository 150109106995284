<template>
  <div>
    <div class="backgroundOV"></div>
    <div>
      <v-card outlined>
        <v-card-title class="titleLarge"
          >Operationsverständnis – Übergang Diagnose</v-card-title
        >
      </v-card>
      <br />
      <br />
      <v-card outlined>
        <v-card-title class="titleSmaller"
          >Welche Facetten des Hintergrundwissens konnten beim
          Operationsverständnis ausgemacht werden?</v-card-title
        >
        <v-card-text>
          Das Hintergrundwissen im Bereich Operationsverständnis lässt sich
          aufgliedern in die drei Facetten:
          <ul>
            <li>Grundvorstellungen besitzen</li>
            <li>Darstellungen vernetzen</li>
            <li>Aufgabenbeziehungen nutzen</li>
          </ul>
          Das Wissen um diese drei Teilbereiche ist vor allem auch für die
          Diagnose und Förderung der Kinder wichtig, um zum einen
          diagnostizieren zu können, wo genau beispielsweise Probleme auftreten
          und daran anschließend eine passgenaue Förderung anzustreben.
        </v-card-text>
      </v-card>
      <br />
      <br />
      <v-card outlined>
        <v-card-title class="titleSmaller"
          >Was erwartet Sie im Bereich der Diagnose und Förderung im
          Operationsverständnis?
        </v-card-title>
        <v-card-text>
          In drei Aufgaben betrachten Sie gleich Lösungen von Kindern, um
          <ul>
            <li>
              Fehler im Bereich des Operationsverständnisses zu diagnostizieren
              und
            </li>
            <li>
              eine Förderaufgabe für ein Kind diagnosegeleitet auszuwählen
            </li>
            <li>
              zudem sollen Sie Aufgabenbeispiele den unterschiedlichen
              Hintergrundfacetten zuordnen, um den Fokus darauf zu legen,
              welchen Schwerpunkt diese Aufgabe legt.
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </div>
    <AppBottomNavOV
      back="/operationsverstaendnis/hintergrundwissen/aufgabenbeziehungen"
      next="/operationsverstaendnis/diagnose-und-foerderung"
    />
  </div>
</template>

<script>
import AppBottomNavOV from "@/common/AppBottomNavOV";
export default {
  components: {
    AppBottomNavOV,
  },
};
</script>

<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
